import request from '@/utils/request'

const mediasApi = {
  // get medias info
  medias: '/api/v1/rest/medias',
  findByKeyword: '/api/v1/rest/medias/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/medias/search/findByHouseId'
}

/**
 * list
 * @param parameter { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function medias (parameter) {
  return request({
    url: mediasApi.medias + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * add
 * @param parameter
 * @returns {*}
 */
export function addMedias (parameter) {
  return request({
    url: mediasApi.medias,
    method: 'post',
    data: parameter
  })
}

/**
 * add
 * @param data {type: '', path: ''}
 * @returns {*}
 */
export function saveMedias (data) {
  return request({
    url: mediasApi.medias,
    method: 'post',
    data
  })
}

/**
 * edit
 * @param parameter { id: '', sort: '' }
 * @returns {*}
 */
export function editMedias (id, data) {
  return request({
    url: mediasApi.medias + `/${id}`,
    method: 'patch',
    data
  })
}

/**
 * get
 * @param parameter id
 * @returns {*}
 */
export function getMedia (id) {
  return request({
    url: mediasApi.medias + `/${id}`,
    method: 'get'
  })
}

/**
 * del
 * @param parameter { id: '' }
 * @returns {*}
 */
export function delMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}`,
    method: 'delete'
  })
}

/**
 * search
 * @param parameter { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findMedias (parameter) {
  return request({
    url: mediasApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * search
 * @param parameter { id: '' }
 * @returns {*}
 */
export function findByIdMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}`,
    method: 'get'
  })
}

/**
 * medias
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function inMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

/**
 * add
 * @param parameter { name: '', content: '' }
 * @returns {*}
 */
export function addInMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}/${parameter.name}`,
    data: parameter
  })
}

/**
 * patch
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function editInMedias (parameter) {
  return request({
    url: mediasApi.medias + `/${parameter.id}/${parameter.name}`,
    method: 'patch'
  })
}

/**
 * findByHouses_Id
 * @param parameter { id: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function findByHousesIdMedias (parameter) {
  return request({
    url: mediasApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}
