import request from '@/utils/request'

const roomsApi = {
  // get rooms info
  rooms: '/api/v1/rest/rooms',
  findByKeyword: '/api/v1/rest/rooms/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/rooms/search/findByHouseId'
}

/**
 * list
 * @param parameter { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function rooms (parameter) {
  return request({
    url: roomsApi.rooms + `?page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * id
 * @param parameter id
 * @returns {*}
 */
export function getRooms (id) {
  return request({
    url: roomsApi.rooms + `/${id}`,
    method: 'get'
  })
}

/**
 * add
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function addRooms (parameter) {
  return request({
    url: roomsApi.rooms,
    method: 'post',
    data: parameter
  })
}

/**
 * edit
 * @param parameter { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}`,
    method: 'patch',
    data: parameter
  })
}

/**
 * put Diagrams
 */
export function putRoomsDiagrams (roomId, data) {
  return request({
    url: roomsApi.rooms + '/' + roomId + '/diagrams',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * put models
 */
export function putRoomsModels (roomId, data) {
  return request({
    url: roomsApi.rooms + '/' + roomId + '/models',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * del
 * @param parameter { id: '' }
 * @returns {*}
 */
export function delRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}`,
    method: 'delete'
  })
}

/**
 * search
 * @param parameter { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findRooms (parameter) {
  return request({
    url: roomsApi.findByKeyword + `?keyword=${parameter.keyword}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get'
  })
}

/**
 * search
 * @param parameter { id: '' }
 * @returns {*}
 */
export function findByIdRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}`,
    method: 'get'
  })
}

/**
 * rooms
 * @param parameter { id: '', name: '' }
 * @returns {*}
 */
export function inRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.id}/${parameter.name}`,
    method: 'get'
  })
}

/**
 * houses
 * @param parameter { houseId: '', requestName: '', method: '', data: `${}` }
 * @returns {*}
 */
export function bindRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.roomId}/${parameter.requestName}`,
    method: parameter.method,
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data: parameter.data
  })
}

/**
 * add
 * @param parameter { requestId: '', requestName: '', code: '' }
 * @returns {*}
 */
export function addInRooms (parameter) {
  return request({
    url: roomsApi.rooms + `/${parameter.requestId}/${parameter.requestName}`,
    method: 'post',
    data: parameter
  })
}

/**
 * patch
 * @param parameter { id: '', name: '', requestId: '', requestName: ''}
 * @returns {*}
 */
export function editInRooms (parameter) {
  return request({
    url: roomsApi.Rooms + `/${parameter.requestId}/${parameter.requestName}`,
    method: 'patch'
  })
}

/**
 * findByHouses_Id
 * @param parameter { id: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function findByHousesIdRooms (parameter) {
  return request({
    url: roomsApi.findByHouses_Id + `?houseId=${parameter.id}&page=${parameter.page}&size=${parameter.size}&sort=${parameter.sort}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/hal+json'
    }
  })
}
